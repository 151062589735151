.container-profile {
  height: 100%;
  display: flex;
  justify-content: center;
  background-image: linear-gradient(hsl(73, 15%, 96%), hsl(103, 70%, 71%));
  align-content: center;
  box-shadow: none;
  font-weight: 700;
  overflow-y: scroll;
  position: relative;
  z-index: 2;
}
.container {
  height: 65vh;
  display: grid;
  padding-inline: 20px;
  margin-bottom: 20px;
  grid-template-columns: repeat(4, minmax(200px, 12vw));
  grid-template-rows: repeat(2, minmax(150px, 20vh));
  justify-content: center !important;
  align-content: center;
  background-color: hsl(0 0% 100%/0.5);
  gap: 20px;
  grid-template-areas:
    "main1 sec third fourth"
    "main1 fifth sixth seven";
  color: white;
  border-radius: 15px;
}

.container div {
  border-radius: 15px;
}
.infoWrapper {
  margin-top: 20px;
}

.item1 {
  background-color: hsl(246, 80%, 60%);
  grid-area: main1;
}
.empty-div-profile {
  height: 7vw;
}
.item2 {
  background-color: hsl(15, 100%, 70%);
  grid-area: sec;
  background-repeat: no-repeat;
  background-position: top right;
}
h1,
h2,
h5,
p,
h3 {
  padding: 10px 20px;
  text-align: left;
}
h1,
h2,
p {
  font-weight: 600;
}
h2 {
  margin-bottom: 0;
  padding-bottom: 0;
}
h4 {
  text-align: center;
  margin-top: 7% !important;
}
.item3 {
  background-color: hsl(195, 74%, 62%);
  grid-area: third;
  background-repeat: no-repeat;
  background-position: top right;
}
.item3 h2 {
  text-align: left;
}
.item4 {
  background-color: hsl(348, 100%, 68%);
  grid-area: fourth;
  background-repeat: no-repeat;
  background-position: top right;
}
.item4 h2 {
  text-align: left;
}
.item5 {
  background-color: hsl(145, 58%, 55%);
  grid-area: fifth;
  background-repeat: no-repeat;
  background-position: top right;
}
.item5 h2 {
  text-align: left;
}
.item6 {
  background-color: hsl(264, 64%, 52%);
  grid-area: sixth;
  background-repeat: no-repeat;
  background-position: top right;
}
.item6 h2 {
  text-align: left;
}
.item7 {
  background-color: hsl(43, 84%, 65%);
  grid-area: seven;
  background-repeat: no-repeat;
  background-position: top right;
}

.item11 {
  background-color: hsl(199, 71%, 77%);
  height: calc(100% - 34px);
  margin-top: 1vh;
  padding-top: 10px;
}
.item11#main11 {
  background-color: hsl(199, 71%, 77%);
  height: 100%;
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
ul {
  list-style: none;
  padding: 10px 18px;
  text-align: center;
  align-content: center;
  justify-content: center;
}
li {
  padding: 4px;
  margin-bottom: 4vh;
}
button {
  color: hsl(0, 0%, 0%);
  border-style: none;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
button:hover {
  color: hsl(235, 45%, 61%);
}
button:active {
  color: hsl(235, 45%, 61%);
}
.btn-back {
  margin-block: 20px !important;
  margin-left: 30px !important;
  background-size: contain;
  letter-spacing: 1px;
  color: rgb(84, 175, 161) !important;
  border: rgb(111, 211, 196) 2px solid !important;
  border-radius: 12px !important;
  text-shadow: 0 0 2px hsl(0 0% 100%/0.7), 1 0 20px currentColor !important;
  box-shadow: inset 0 0 1px rgb(84, 175, 161), 0 0 4px rgb(84, 175, 161) !important;
  z-index: 1;
}
.container-profile {
  align-content: flex-start !important;
}
.profile-list-container {
  /* margin-top: 20px !important; */
}
.logo-prof {
  height: 17vh;
}
@media screen and (max-width: 900px) {
  .container {
    padding: 170px 60px;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: repeat(2, auto);
  }
  p {
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 650px) {
  .container {
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
    grid-template-areas:
      "main1"
      "sec"
      "third"
      "fourth"
      "fifth"
      "sixth"
      "seven";
  }
  .container div {
    border-radius: 15px;
  }

  .avatar {
    height: 50%;
    margin-top: 30px;
    margin-left: 30px;
  }

  #main {
    border-radius: 15px;
    display: flex;
  }
}
