* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.nav-bar {
  position: relative;
  margin: 20px;
  display: flex;
  max-height: 200px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  z-index: 1;
}
.profile-hi {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding-left: 120px;
  right: 0;
  z-index: 0;
}
.empty {
  flex-grow: 1;
}
.logo {
  height: 13vh;
  max-height: 100px;
  z-index: 1;
}
.logo-link {
  z-index: 1;
}
.logo-profile-search {
  height: 6vh;
}
.logo2 {
  z-index: 1;
  height: 24vh;
  max-height: 200px !important;
}
.btn-logo2:hover {
  background-color: transparent !important;
  box-shadow: none !important;
}
.login-btn {
  width: auto;
  background-size: contain;
  letter-spacing: 1px;
  color: rgb(84, 175, 161) !important;
  border: rgb(111, 211, 196) 2px solid !important;
  margin-left: 15px !important;
  border-radius: 12px !important;
  text-shadow: 0 0 2px hsl(0 0% 100%/0.7), 1 0 20px currentColor !important;
  box-shadow: inset 0 0 1px rgb(84, 175, 161), 0 0 4px rgb(84, 175, 161) !important;
  z-index: 1;
}
.login-btn:hover {
  background-color: rgb(84, 175, 161) !important;
  color: rgb(189, 236, 229) !important;
  text-shadow: none !important;
}
.btn-log-reg {
  margin-top: 20px !important;
}
.btn-add {
  border: none !important;
  box-shadow: none !important;
  height: 60% !important;
  max-height: 60px !important;
  margin-left: 0 !important;
  font-size: clamp(16px, 2vw, 22px) !important;
}
.makeStyles-accordionContainer-5 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.MuiAccordionSummary-content-145 {
  align-items: center !important;
}
.MuiAccordionSummary-content * {
  align-items: center !important;
}
.MuiAccordionSummary-content-158 {
  align-items: center !important;
}
.app-container {
  /* align-items: center;
  justify-content: center; */
}
.accordion-summary-item > * {
  align-items: center;
}
.search-container {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-block: 30px !important;
  z-index: 1;
  width: 100%;
}

.search-bar {
  z-index: 1;
}
.search-add {
  background: linear-gradient(270deg, #63d6b7, #05412d, #63d6b7);
  background-size: 400% 400%;
  margin-bottom: 20px !important;

  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background-image: linear-gradient(
    to right,
    rgb(77, 124, 116),
    rgb(62, 240, 115)
  ) !important;
   */
}
@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.btn-search {
  color: rgb(84, 175, 161) !important;
  border: rgb(111, 211, 196) 2px solid !important;
  margin-left: 15px !important;
  padding: 5px 20px !important;
  border-radius: 12px !important;
  z-index: 1;

  text-shadow: 0 0 2px hsl(0 0% 100%/0.7), 1 0 18px currentColor !important;
  box-shadow: inset 0 0 1px rgb(84, 175, 161), 0 0 4px rgb(84, 175, 161) !important;
}
.btn-search:hover,
.btn-search:focus {
  background-color: rgb(84, 175, 161) !important;
  color: rgb(189, 236, 229) !important;
  text-shadow: none !important;
}

.result-list-container {
  width: 65% !important;
  display: flex !important;
  flex-direction: column !important;
  padding: 10px;
  z-index: 1;

  border-radius: 15px !important;
}
.result-item-container {
  width: 100% !important;
  display: flex;
  align-items: center;
  z-index: 1;
}

.spoonacular-nutrient-name {
  font-weight: bold !important;
  font-size: 24px !important;
  color: black !important;
  /* margin-right: 20px !important; */
}
.spoonacular-nutrient-value {
  font-weight: bold !important;
  font-size: 24px !important;
}
.spoonacular-caption {
  font-size: 24px !important;
  width: 200px !important;
}
.spoonacular-quickview {
  font-size: 24px !important;
}
/* .spoonacular-nutrition-visualization-bar {
  max-width: calc(100% - 100px) !important;
} */

.image {
  padding-right: 8%;
  max-height: 100px;
  min-height: 70px;
  min-width: 70px;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  background-origin: content-box;
}

.btn-loadmore {
  letter-spacing: 2px;

  margin-top: 20px !important;
  padding-inline: 20px !important;
  margin-bottom: 60px !important;
  color: rgb(84, 175, 122) !important;
  border: rgb(111, 211, 161) 2px solid !important;
  margin-left: 15px !important;
  border-radius: 12px !important;
  text-shadow: 0 0 2px hsl(0 0% 100%/0.7), 1 0 18px currentColor !important;
  box-shadow: inset 0 0 1px rgb(84, 175, 161), 0 0 4px rgb(84, 175, 161) !important;
}
.btn-loadmore:hover {
  background-color: rgb(84, 175, 122) !important;
  color: rgb(189, 236, 229) !important;
  text-shadow: none !important;
}
.spacer {
  position: fixed;
  aspect-ratio: 960/300;
  width: 99%;
  height: 100%;
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden !important;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.layer1 {
  overflow: hidden !important;

  z-index: 0;
  background-image: url("./layered-waves-haikei.svg");
}
.image-landing {
  /* max-height: 350px; */
  width: 30vw;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  background-origin: content-box;
}
.landing-container {
  z-index: inherit;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  background-color: hsl(0 0% 100%/ 0.5) !important;
  border-radius: 20px !important;
  width: 65% !important;
  align-items: center;
}
.landing-text-container {
  width: fit-content !important;
  height: fit-content !important;
  z-index: inherit;
  flex-direction: column;
  /* padding-right: 7%; */
  padding-top: 10px;
  align-items: center;

  /* align-items: flex-start !important; */
}
.landing-text {
  letter-spacing: 1px;
  color: rgb(16, 61, 78);
  text-shadow: 0 0 2px hsl(0 0% 100%/0.9), 2px 2px 5px hsl(196 66% 18%/0.5) !important;
}

.landing-btn {
  margin-block: 5px !important;
  margin-inline: 0 !important;
  border-radius: 40px !important;
  width: 25vw !important;
  height: 11vh !important;
  /* margin-bottom: 10px !important; */
  background-size: contain;
  letter-spacing: 1px;
  color: rgb(255, 255, 255) !important;
  background-color: rgb(231, 127, 96) !important;
  font-size: 4vh !important;
  border: rgb(231, 127, 96) 2px solid !important;
  text-shadow: 0 0 2px hsl(0 0% 100%/0.7), 0 0 14px currentColor !important;
  box-shadow: 0 0 10px rgb(231, 127, 96) !important;
  z-index: 1;
}
.landing-btn:hover {
  background-color: rgb(197, 94, 63) !important;
  text-shadow: 0 0 2px hsl(0 0% 100%/0.7), 0 0 20px currentColor !important;

  color: rgb(255, 255, 255) !important;
  text-shadow: none !important;
}

.profile-reg-container {
  padding-top: 5%;
  width: 80vw !important;
  height: 70% !important;
  display: flex !important;
  flex-direction: row !important;
  z-index: inherit;
  flex-wrap: nowrap !important;
  align-items: flex-start !important;
}
.reg {
  max-width: 550px;
}
.joke-container {
  padding: 10px;
  margin-left: 30px;
  width: 100%;
  z-index: 1;
  border-radius: 15px;
  background-color: hsl(0 0% 100%/ 0.4) !important;
}
.nodata-msg {
  color: rgb(212, 27, 27) !important;
  text-align: center !important;
}
.chart-item {
  z-index: 10 !important;
}
.skeleton {
  z-index: 1 !important;
  width: 60vw !important;
  height: 120px !important;
}
@media (min-width: 768px) {
  .result-list-container {
    align-content: center;
  }
}
@media (max-width: 420px) {
  .logo {
    height: 9vh;
  }
  .logo2 {
    height: 12vh;
  }
  .nav-bar {
    align-items: center;
    margin-top: -50px;
    margin-bottom: 50px;
  }
  .landing-container {
    width: 75% !important;
    flex-direction: column !important;
    justify-content: space-around !important;
    /* height: 65%; */
  }
  .landing-title {
    font-size: 7.8vw !important;
  }
  .landing-body {
    font-size: 5.2vw !important;
  }
  .landing-btn {
    width: 66vw !important;
  }
  .profile-reg-container {
    flex-wrap: wrap !important;
  }
  .joke-container {
    margin-left: 0 !important;
    padding: 0;
    margin-top: 10px;
  }
  .result-list-container {
    margin: 0 !important;
    margin-bottom: 10px !important;
    padding: 0 !important;
    min-width: 0;
    width: 85vw !important;
  }
  .makeStyles-accordionContainer-10 {
    margin: 0 !important;
    margin-bottom: 10px !important;
    padding: 0 !important;
    min-width: 0;
    width: 85vw !important;
  }
}
